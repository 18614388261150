
import { ref, watch } from 'vue';
// import { loadLanguageAsync, trans, transChoice } from 'laravel-vue-i18n';

let langInParam = (new URL(window.location.href)).pathname.split('/')[1];
if (langInParam.length > 2) { langInParam = null; }
let lang = langInParam ? langInParam : localStorage.getItem('locale');
let defaultLang = 'fr';
export const locale = ref(lang ? lang : defaultLang);

// pll_language is not cross-domain
// console.error('pll_language', getCookie('pll_language'));

// TOFIX: Because route() does not exits in Meditation App :/ @see @routes
const route = (name, params) => {
    // console.debug('route', { name: name, params: params });
    var a = {
        'xhr.language.switch': `/xhr/language/${locale.value}`,
    }

    if (a[name]) {
        return a[name];
    }

    console.error('route?', { name: name, params: params });
}

export const watchLocale = () => {
    let url = route('xhr.language.switch', { locale: locale.value });
    // console.debug('watchLocale()', { locale: locale.value, url: url });

    // loadLanguageAsync(locale.value);

    return axios.get(url)
        .then((response) => {
            return true;
        })
        .catch(error => {
            // If this .catch is commented, axios while throw an exception bc of the abort().
            console.log('Error', error);

            return error;
        });
}

// watch(() => locale.value,
//     () => {
//         watchLocale()
//     });

watchLocale();